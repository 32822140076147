import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Troubleshooting`}</h2>
    <h3>{`Disable firewall`}</h3>
    <p><strong parentName="p">{`Fix service-to-service communication`}</strong></p>
    <pre><code parentName="pre" {...{}}>{`firewall-cmd --set-default-zone=trusted
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      